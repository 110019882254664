<template>
  <hr />
</template>

<style lang="scss" scoped>
hr {
  height: 2px;
  border: none;
  width: 100%;
  opacity: 0.2;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(255, 255, 255), rgba(0, 0, 0, 0));
}
</style>