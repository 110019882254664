<template>
    <NuxtLink :href="insideLink ? href : null" :to="!insideLink ? href : null" target="_SELF" @click="goTo">
        <div :class="{primary, secondary}" class="button">
            <span>
              <slot/>
            </span>
            <i/>
        </div>
    </NuxtLink>
</template>

<script setup>
const props = defineProps({
    primary: Boolean,
    secondary: Boolean,
    href: String,
    target: String,
})

const insideLink = !props.href || props?.href.includes('#');
const goTo = () => {
    if (!insideLink) return;
    const hash = props.href;
    let element = document.querySelector(hash);

    if (element) {
        window.scrollTo({
            top: element.offsetTop,
            behavior: 'smooth'
        });
        window.location.hash = hash;
    }
}


</script>

<style lang="scss" scoped>
a {
    text-decoration: none;
}

.button {
    position: relative;
    box-shadow: none;
    background-color: $bg-2;
    color: white;
    border: 1px solid $border-color-1;
    font-weight: 500;
    font-size: em(16px);
    padding: calc($gutter * 0.6) $gutter;
    border-radius: 500px;
    overflow: hidden;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
        background-color: darken($bg-2, 2%);
    }


    &:after {
        transition: all .2s ease;
    }

    span {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        align-content: center;
        gap: calc($gutter / 4);
    }

    i {
        display: none;
    }

    &.secondary {
        color: $txt-dark;
        font-weight: 600;
        background-color: rgba(white, 1);
        border: 1px solid rgba($txt-dark, .5);
        overflow: hidden;

        &:after {
            content: '';
            position: absolute;
            inset: 0;
            opacity: .65;
            background-image: linear-gradient(180deg, $primary, $secondary);
            filter: blur(10px);
            scale: 2;
        }

        &:hover {
            &:after {
                opacity: .8;
            }
        }
    }

    &.primary {
        i {
            display: block;
            pointer-events: none;
            position: absolute;
            inset: 0;
            z-index: 0;
            padding: 1.5px;
            transition-property: all;
            transition-timing-function: cubic-bezier(.4, 0, .2, 1);
            transition-duration: .2s;
            mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            mask-composite: exclude;
            border-radius: inherit;

            &:before {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                display: block;
                border-radius: 9999px;
                opacity: 1;
                background: conic-gradient(
                        from 180deg at 50% 50%,
                        complement($primary) 0deg,
                        lighten(complement($primary), 20%) 10deg,
                        lighten($secondary, 10%) 25deg,
                        $secondary 112.5deg,
                        $primary 203.75deg,
                        lighten($primary, 20%) 213.75deg,
                        lighten($primary, 10%) 228.75deg,
                        rgba(42, 138, 246, 0) 1turn);
                width: calc(100% * 2);
                padding-bottom: calc(100% * 2);
                transform: translate(-50%, -50%);
                z-index: -1;
                animation: hover-spin__animation 5s linear infinite;
            }
        }

    }
}

@keyframes hover-spin__animation {
    0% {
        transform: translate(-50%, -50%) rotate(1turn)
    }

    to {
        transform: translate(-50%, -50%) rotate(0)
    }
}
</style>