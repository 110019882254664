<template>
  <img src="@images/Lines.png" class="lines" alt="lines"/>
</template>

<style lang="scss" scoped>
.lines {
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  transform: translate(-50%, -30%);
}
</style>